import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Making better decisions with the WADM",
  "author": "Patrick Passarella",
  "date": "2021-01-07",
  "subtitle": "WADM is a formula to make mathematically better decisions based on factors that matter",
  "cover": "./cover.jpg",
  "coverCredit": "Piotr Makowski",
  "coverWebsite": "Unsplash",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`WADM (Weighted Average Decision Matrix) is something I've learned to use that helped me make better professional and personal decisions. The first time I've heard of it was in the book `}<em parentName="p">{`The Millionaire Fastlane`}</em>{`, since then, I have been using it sometimes. Usually, I don't like these types of "tools", but this one works well for me.`}</p>
    <p>{`Making decisions is sometimes very hard, but important, and you also need to have confidence in it. There can be so much involved that it's better to have a structured way to think. When there are multiple alternatives to choose from, each alternative must be examined equally, or it could cost you the wrong decision consequences.`}</p>
    <p>{`WADM objective is simple, make you decide between two or more things consistently, based on weighted factors that matter.`}</p>
    <h2>{`How does it work`}</h2>
    <p><strong parentName="p">{`First, you'll need 3 things`}</strong></p>
    <ul>
      <li parentName="ul">{`A list of criteria;`}</li>
      <li parentName="ul">{`A set of weights based on importance for the criteria;`}</li>
      <li parentName="ul">{`A set of alternatives.`}</li>
    </ul>
    <p>{`Now with this information, it's easy to form a table, I will give an example. I want to decide what technology is best for me to learn. It's all fictional data since I don't have much experience in some of them. so don't take it personally.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Criteria`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Weight`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`React`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Angular`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Vue`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Learn speed`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Difficult`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Community`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Current Knowledge`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Useful`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`2`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Criteria`}</h3>
    <p>{`These are the factors that I find most important for me to decide which technology I want to learn.`}</p>
    <h3>{`Weight`}</h3>
    <p>{`The weight is what defines the criteria priority, I use 1 through 5, but you can use any format you like. 1 is the least important, and 5 the most important. A technology that has a strong community and the speed to learn is the top priority in this example.`}</p>
    <h3>{`Alternatives`}</h3>
    <p>{`The options you want to compare. For me, it's three technologies, React, Angular, and Vue. And a rating for each factor on a number from 1 through 5. In this example, React got 5 in Community, because it has a great and active community, and Angular got 2 in Learn speed because I find it slower to learn.`}</p>
    <p>{`Then, you multiply each option for each factor and add them together to get the total points for each alternative.`}</p>
    <h2>{`How to apply WADM step by step`}</h2>
    <p>{`The WADM method is very flexible, but I find that there are ways that could help you get a more precise result. So I will try to write a general step by step process to use this formula whenever you need it.`}</p>
    <p><strong parentName="p">{`1. List all the options you want to compare`}</strong><br parentName="p"></br>{`
`}{`eg: React, Angular, Vue.`}</p>
    <p><strong parentName="p">{`2. List all the factors`}</strong><br parentName="p"></br>{`
`}{`eg: Learn speed, Difficult, Community, Current Knowledge, Useful.`}</p>
    <p><strong parentName="p">{`3. Rate each option for each factor from 1 through 5`}</strong><br parentName="p"></br>{`
`}{`eg: 5 for React in Community, 5 for Vue in Learn speed.`}</p>
    <p><strong parentName="p">{`4. Weight the factors from 1 through 5`}</strong><br parentName="p"></br>{`
`}{`eg: Community equals 5 weight, Difficult equals 2 weight.`}</p>
    <p>{`We need to weigh each factor `}<strong parentName="p">{`after`}</strong>{` rating the options, because doing it before may influence your choices and change your outcome.`}</p>
    <p><strong parentName="p">{`5. Multiply each option point to the respective factor weight`}</strong><br parentName="p"></br>{`
`}{`eg: Difficult has weight 2, and React has a rating of 4 for the Difficult factor, so 4 `}{`*`}{` 2, which equals 8 points.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Criteria`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Weight`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`React`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Angular`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Vue`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Learn speed`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`4 `}{`*`}{` 5 = 20`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`2 `}{`*`}{` 5 = 10`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5 `}{`*`}{` 5 = 25`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Difficult`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`4 `}{`*`}{` 2 = 8`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3 `}{`*`}{` 2 = 6`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5 `}{`*`}{` 2 = 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Community`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5 `}{`*`}{` 5 = 25`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`4 `}{`*`}{` 5 = 20`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3 `}{`*`}{` 5 = 15`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Current Knowledge`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3 `}{`*`}{` 4 = 12`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5 `}{`*`}{` 4 = 20`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3 `}{`*`}{` 4 = 12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Useful`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5 `}{`*`}{` 3 = 15`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3 `}{`*`}{` 3 = 9`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`2 `}{`*`}{` 3 = 6`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`6. Sum all the points`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Criteria`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Weight`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`React`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Angular`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Vue`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Learn speed`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`20`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`25`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Difficult`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Community`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`25`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`20`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`15`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Current Knowledge`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`20`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Useful`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Points`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`80`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`65`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`70`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`In this fictional scenario, based on all the factors, React is a better choice`}{`.`}{` Maybe I overworked all of this, it's actually very straightforward. Give it a try! Whatever it is a personal or professional decision, it will help you choose more wisely.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      